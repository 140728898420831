/**
 * `cooori-plugin-zoho-salesiq` allows specifying paths on which
 * to hide the chat widget.
 *
 * This is achieved here by hiding the floating chat button zoho
 * provides if the route's location matches any of the paths to
 * hide on.
 *
 * This **only** toggles visibility of the floating chat button.
 * If the widget is set up to automatically initiate a chat session
 * it will **NOT** hide the chat window itself.
 */

exports.onRouteUpdate = ({ location } = {}) => {
    if (
        window.$zoho === undefined ||
        window.$zoho.salesiq === undefined ||
        window.COOORI_PLUGIN_ZOHO_SALESIQ == undefined
    ) {
        return
    }

    const { isInitialized, hideForPaths } = window.COOORI_PLUGIN_ZOHO_SALESIQ
    const hideChat = hideForPaths.some((pathRegExp) =>
        pathRegExp.test(location.pathname)
    )

    // If SalesIQ is already initialized we can call the visibility function directly
    if (isInitialized) {
        window.$zoho.salesiq.floatbutton.visible(hideChat ? 'hide' : 'show')
    } else {
        // Otherwise we can set that it should be called once ready
        window.$zoho.salesiq.afterReady = () => {
            window.$zoho.salesiq.floatbutton.visible(hideChat ? 'hide' : 'show')
        }
    }
}
