exports.components = {
  "component---src-modules-blog-index-page-tsx": () => import("./../../../src/modules/blog/IndexPage.tsx" /* webpackChunkName: "component---src-modules-blog-index-page-tsx" */),
  "component---src-modules-blog-post-layout-tsx": () => import("./../../../src/modules/blog/PostLayout.tsx" /* webpackChunkName: "component---src-modules-blog-post-layout-tsx" */),
  "component---src-modules-blog-posts-page-tsx": () => import("./../../../src/modules/blog/PostsPage.tsx" /* webpackChunkName: "component---src-modules-blog-posts-page-tsx" */),
  "component---src-modules-cs-content-page-layout-tsx": () => import("./../../../src/modules/cs-content/PageLayout.tsx" /* webpackChunkName: "component---src-modules-cs-content-page-layout-tsx" */),
  "component---src-modules-grammar-videos-index-page-tsx": () => import("./../../../src/modules/grammar-videos/IndexPage.tsx" /* webpackChunkName: "component---src-modules-grammar-videos-index-page-tsx" */),
  "component---src-modules-grammar-videos-page-layout-tsx": () => import("./../../../src/modules/grammar-videos/PageLayout.tsx" /* webpackChunkName: "component---src-modules-grammar-videos-page-layout-tsx" */),
  "component---src-modules-hr-faq-hrfaq-overview-page-tsx": () => import("./../../../src/modules/hr-faq/HRFAQOverviewPage.tsx" /* webpackChunkName: "component---src-modules-hr-faq-hrfaq-overview-page-tsx" */),
  "component---src-modules-hr-faq-hrfaq-page-tsx": () => import("./../../../src/modules/hr-faq/HRFAQPage.tsx" /* webpackChunkName: "component---src-modules-hr-faq-hrfaq-page-tsx" */),
  "component---src-modules-pillar-pillar-layout-tsx": () => import("./../../../src/modules/pillar/PillarLayout.tsx" /* webpackChunkName: "component---src-modules-pillar-pillar-layout-tsx" */),
  "component---src-modules-sales-content-page-layout-tsx": () => import("./../../../src/modules/sales-content/PageLayout.tsx" /* webpackChunkName: "component---src-modules-sales-content-page-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-english-learning-features-tsx": () => import("./../../../src/pages/ai-english-learning-features.tsx" /* webpackChunkName: "component---src-pages-ai-english-learning-features-tsx" */),
  "component---src-pages-ai-english-learning-study-flow-tsx": () => import("./../../../src/pages/ai-english-learning-study-flow.tsx" /* webpackChunkName: "component---src-pages-ai-english-learning-study-flow-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-ai-online-english-learning-service-tsx": () => import("./../../../src/pages/faq-ai-online-english-learning-service.tsx" /* webpackChunkName: "component---src-pages-faq-ai-online-english-learning-service-tsx" */),
  "component---src-pages-hr-tsx": () => import("./../../../src/pages/hr.tsx" /* webpackChunkName: "component---src-pages-hr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-system-requirements-tsx": () => import("./../../../src/pages/system-requirements.tsx" /* webpackChunkName: "component---src-pages-system-requirements-tsx" */)
}

